////import Swal from "sweetalert2";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../../Shared/HelperMixin.vue'

export default {
    name: "Add",
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        this.GetNationalites();
        this.GetCities();
        this.GetCompanies();
    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },
    },
    data() {
        return {
            Nationality: [],
            Cities: [],
            Municipalities: [],
            KidnyCenter: [],
            Companies: [],
            Devices: [],
            Felters: [],


            ruleForm: {
                Id: 0,

                FirstName: '',
                FatherName: '',
                GrandFatherName: '',
                LastName: '',

                
                NationalyId:'',
                NID: '',
                Passport: '',
                MaritalStatus: '',

                Gender: '',
                BirthDate: '',
                Address: '',
                Workplace: '',
                CityId: '',

                Phone: '',
                Phone1: '',
                Relations: '',

                CityCenterId: '',
                MunicipalitiesId: '',
                KidneyCenterId: '',

                BloodType: '',
                ViralAssays: '',

                KidneyFailureDate:'',
                KidneyFailureCause: '',
                KidneyFailureResone: '',

                Status:1,
                CompanyId:'',
                DeviceId:'',
                FilterId: '',

                FileNumber:'',
                Sa:'',
                Su:'',
                Mo:'',
                Tu:'',
                We:'',
                Th: '',
                Fr: '',
                Day: [],

                Note: '',

                ImageName:'',
                ImageType:'',
                fileBase64:'',
                
            },
            rules: {
                
                FirstName: this.$helper.DynamicArabicEnterRequired('الاسم الاول'),
                FatherName: this.$helper.DynamicArabicEnterRequired('إسم الاب'),
                GrandFatherName: this.$helper.DynamicArabicEnterRequired('إسم الجد'),
                LastName: this.$helper.DynamicArabicEnterRequired(' اللقب'),

                NationalyId: this.$helper.RequiredInput('الجنسية'),
                NID: this.$helper.NID(),
                Passport: this.$helper.RequiredInput('رقم جواز السفر او رقم الهوية'),
                MaritalStatus: this.$helper.RequiredInput('الحالة الاجتماعية '),

                Gender: this.$helper.RequiredInput('الجنس'),
                BirthDate: this.$helper.RequiredInput('تاريخ الميلاد'),
                Address: this.$helper.RequiredInput('مكان السكن'),
                Workplace: this.$helper.RequiredInput('مكان العمل'),
                CityId: this.$helper.RequiredInput(' المدينة'),

                Phone: this.$helper.Phone(),
                Phone1: this.$helper.Phone(),
                Relations: this.$helper.RequiredInput(' صلة القرابة'),

                BloodType: this.$helper.RequiredInput(' فصيلة الدم '),
                ViralAssays: this.$helper.RequiredInput(' التحاليل الفيروسية '),

                KidneyFailureDate: this.$helper.RequiredInput(' تاريخ بداية الفشل الكلوى '),
                KidneyFailureCause: this.$helper.RequiredInput(' سبب الفشل الكلوى '),
                KidneyFailureResone: this.$helper.RequiredInput(' سبب الفشل الكلوى '),

                Status: this.$helper.RequiredInput('حالة الغسيل '),
                CompanyId: this.$helper.RequiredInput('الشركة '),
                DeviceId: this.$helper.RequiredInput('الجهاز '),
                FilterId: this.$helper.RequiredInput('الفلتر '),

                CityCenterId: this.$helper.RequiredInput(' المدينة'),
                MunicipalitiesId: this.$helper.RequiredInput(' البلدية'),
                KidneyCenterId: this.$helper.RequiredInput(' مركز الكلى'),

                FileNumber: this.$helper.RequiredInput(' رقم الملف'),
                

            },
            
        };
    },
    
    methods: {

        GetNationalites() {
            this.$blockUI.Start();
            this.$http
                .GetNationalites()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Nationality = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetCities() {
            this.$blockUI.Start();
            this.$http
                .GetCities()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Cities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetMunicipalities() {
            this.ruleForm.MunicipalitiesId = '';
            this.Municipalities = [];
            this.KidnyCenter = [];
            this.ruleForm.KidnyCenterId = '';
            this.$blockUI.Start();
            this.$http
                .GetMunicipalitiesByCiteisID(this.ruleForm.CityCenterId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetKednyCenter() {
            this.KidnyCenter = [];
            this.ruleForm.KidnyCenterId = '';
            this.$blockUI.Start();
            this.$http
                .GetKednyCenterByCitie(this.ruleForm.MunicipalitiesId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.KidnyCenter = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetCompanies() {
            this.$blockUI.Start();
            this.$http
                .GetAllCompanies()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Companies = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetDviceById() {
            this.$blockUI.Start();
            this.$http
                .GetDviceById(this.ruleForm.CompanyId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Devices = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetFilterById() {
            this.$blockUI.Start();
            this.$http
                .GetFilterById(this.ruleForm.DeviceId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Felters = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.Day=[];
                    if (!this.ruleForm.Sa && !this.ruleForm.Su && !this.ruleForm.Mo && !this.ruleForm.Tu && !this.ruleForm.We && !this.ruleForm.Th && !this.ruleForm.Fr) {
                        this.$helper.ShowMessage('warning', 'عملية غير ناجحة', 'الرجاء تحديد أيام الغسيل');
                        return;
                    } else {
                        if (this.ruleForm.Sa) {
                            this.ruleForm.Day.push(6);
                        }
                        if (this.ruleForm.Su) {
                            this.ruleForm.Day.push(1);
                        }
                        if (this.ruleForm.Mo) {
                            this.ruleForm.Day.push(2);
                        }
                        if (this.ruleForm.Tu) {
                            this.ruleForm.Day.push(3);
                        }
                        if (this.ruleForm.We) {
                            this.ruleForm.Day.push(4);
                        }
                        if (this.ruleForm.Th) {
                            this.ruleForm.Day.push(5);
                        }
                        if (this.ruleForm.Fr) {
                            this.ruleForm.Day.push(7);
                        }
                    }

                    if (this.ruleForm.Phone1) {
                        if (!this.ruleForm.Relations) {
                            this.$helper.ShowMessage('warning', 'عملية غير ناجحة', 'الرجاء تحديد صلة القرابة');
                        }
                    }

                    this.ruleForm.Id = Number(this.ruleForm.Id);
                    if (this.ruleForm.NationalyId == 2) {
                        this.ruleForm.NID = Number(this.ruleForm.NID);
                    } else {
                        this.ruleForm.NID = 0;
                        this.ruleForm.NID = Number(this.ruleForm.NID);
                    }

                    if (!this.ruleForm.Phone1) {
                        this.ruleForm.Relations = 0;
                        this.ruleForm.Relations = Number(this.ruleForm.Relations);
                    }

                    if (this.loginDetails.userType != 1) {
                        this.ruleForm.KidneyCenterId = 0;
                    }





                    
                    this.$blockUI.Start();
                    this.$http.AddPatient(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.resetForm(formName);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm() {
            //this.$refs[formName].resetFields();
            this.ruleForm.Id = 0;
            this.ruleForm.FirstName = '';
            this.ruleForm.FatherName = '';
            this.ruleForm.GrandFatherName = '';
            this.ruleForm.LastName = '';

            this.ruleForm.NationalyId = '';
            this.ruleForm.NID = '';
            this.ruleForm.Passport = '';
            this.ruleForm.MaritalStatus = '';

            this.ruleForm.Gender = '';
            this.ruleForm.DateOfBirth = '';
            this.ruleForm.Address = '';
            this.ruleForm.Workplace = '';
            this.ruleForm.CityId = '';

            this.ruleForm.Phone = '';
            this.ruleForm.Phone1 = '';
            this.ruleForm.Relations = '';

            this.ruleForm.CityCenterId = '';
            this.ruleForm.MunicipalitiesId = '';
            this.ruleForm.KidneyCenterId = '';

            this.ruleForm.BloodType = '';
            this.ruleForm.ViralAssays = '';

            this.ruleForm.KidneyFailureDate = '';
            this.ruleForm.KidneyFailureCause = '';
            this.ruleForm.KidneyFailureResone = '';

            this.ruleForm.Status = 1;
            this.ruleForm.CompanyId = '';
            this.ruleForm.DeviceId = '';
            this.ruleForm.FilterId = '';

            this.ruleForm.FileNumber = '';
            this.ruleForm.Sa = '';
            this.ruleForm.Su = '';
            this.ruleForm.Mo = '';
            this.ruleForm.Tu = '';
            this.ruleForm.We = '';
            this.ruleForm.Th = '';
            this.ruleForm.Fr = '';
            this.ruleForm.Day = [];

            this.ruleForm.Note = '';

            this.ruleForm.ImageName = '';
            this.ruleForm.ImageType = '';
            this.ruleForm.fileBase64 = '';
        },

        SelectCoverAttachment(file) {
            let str = file.raw.type;
            str = str.substring(0, 5);

            if (str != "image") {
                this.$helper.ShowMessage('error', 'خطأ بالعملية', 'الرجاء التأكد من نوع الملف');
            }

            var $this = this;
            var reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = function () {
                $this.ruleForm.ImageName = file.raw.name;
                $this.ruleForm.ImageType = file.raw.type;
                $this.ruleForm.fileBase64 = reader.result;
            };

        },

    },
};
