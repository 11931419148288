import axios from "axios";

axios.defaults.headers.common["X-CSRF-TOKEN"] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {
    // ********************************************************************| Authintecations |***********************************************************

    login(bodyObjeect) {
        return axios.post(`/Security/loginUser`, bodyObjeect);
    },

    AddMuntisbl(bodyObjeect) {
        return axios.post(`/api/admin/Municipalities/Add`, bodyObjeect);
    },

    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },










    // ********************************************************************| Patinet |***********************************************************


    GetPatient(pageNo, pageSize, CityId, MunicipalitId, HospitalId, PatientStatus, PatientId, CompanyId, DeviceId, FilterId) {
        return axios.get(`api/admin/Patients/Get?pageNo=${pageNo}&pageSize=${pageSize}&CityId=${CityId}
        &MunicipalityId=${MunicipalitId}&KidneyCenterId=${HospitalId}&PatientStatus=${PatientStatus}&PatientId=${PatientId}
        &CompanyId=${CompanyId}&DeviceId=${DeviceId}&FilterId=${FilterId}`);
    },

    GetReportPatientDetails(CityId, MunicipalitId, KidneyCenterId) {
        return axios.get(`api/admin/Reports/GetReportPatientDetails?CityId=${CityId}&MunicipalityId=${MunicipalitId}&KidneyCenterId=${KidneyCenterId}`);
    },

    GetAllPatientToPrint() {
        return axios.get(`api/admin/Patients/GetAllPatientToPrint`);
    },

    GetPatientById(code) {
        return axios.get(`api/admin/Patients/GetByName?code=${code}`);
    },

    GetPatientByIdActive(code) {
        return axios.get(`api/admin/Patients/GetByNameActive?code=${code}`);
    },

    GetPatientByIdAllActive(code) {
        return axios.get(`api/admin/Patients/GetByNameAllActive?code=${code}`);
    },

    AddPatient(bodyObject) {
        return axios.post(`api/admin/Patients/Add`, bodyObject);
    },

    EditPatient(bodyObject) {
        return axios.post(`api/admin/Patients/Edit`, bodyObject);
    },

    DeletePatient(Id) {
        return axios.post(`api/admin/Patients/${Id}/Delete`);
    },




    // Attachemtn
    AddPatientAttahcment(bodyObject) {
        return axios.post(`api/admin/Patients/Attachments/Add`, bodyObject);
    },

    GetPatientAttachment(Id) {
        return axios.get(`api/admin/Patients/Attachments/Get?Id=${Id}`);
    },

    DeletePatientAttachment(Id) {
        return axios.post(`api/admin/Patients/${Id}/Attachments/Delete`);
    },



    // Phone
    AddPatientPhone(bodyObject) {
        return axios.post(`api/admin/Patients/Phone/Add`, bodyObject);
    },

    GetPatientPhone(Id) {
        return axios.get(`api/admin/Patients/Phone/Get?Id=${Id}`);
    },

    DeletePatientPhone(Id) {
        return axios.post(`api/admin/Patients/${Id}/Phone/Delete`);
    },



    // Schedule
    AddPatientSchedule(bodyObject) {
        return axios.post(`api/admin/Patients/Schedule/Add`, bodyObject);
    },

    GetPatientSchedule(Id) {
        return axios.get(`api/admin/Patients/Schedule/Get?Id=${Id}`);
    },

    DeletePatientSchedule(Id) {
        return axios.post(`api/admin/Patients/${Id}/Schedule/Delete`);
    },



    //Transactins
    GetPatientTransactions(pageNo, pageSize, PatientId) {
        return axios.get(`api/admin/Patients/Transactions/Get?pageNo=${pageNo}&pageSize=${pageSize}&PatientId=${PatientId}`);
    },



    //Cahnge Request
    AddChangeRequest(bodyObject) {
        return axios.post(`api/admin/Patients/ChangeRequest/Add`, bodyObject);
    },

    GetChangeRequest(pageNo, pageSize, CityId, MunicipalitiesId, KidneyCenterId, Level, PatientId) {
        return axios.get(`api/admin/Patients/ChangeRequest/Get?pageno=${pageNo}&pagesize=${pageSize}&CityId=${CityId}&MunicipalitiesId=${MunicipalitiesId}&KidneyCenterId=${KidneyCenterId}&Level=${Level}&PatientId=${PatientId}`);
    },

    GetCenterChangeRequest(pageNo, pageSize, Level, PatientId) {
        return axios.get(`api/admin/Patients/ChangeRequest/GetRequest?pageno=${pageNo}&pagesize=${pageSize}&Level=${Level}&PatientId=${PatientId}`);
    },

    GetMyCenterChangeRequest(pageNo, pageSize, Level, PatientId) {
        return axios.get(`api/admin/Patients/ChangeRequest/GetMyRequest?pageno=${pageNo}&pagesize=${pageSize}&Level=${Level}&PatientId=${PatientId}`);
    },

    DeleteChangeRequest(Id) {
        return axios.post(`api/admin/Patients/${Id}/ChangeRequest/Delete`);
    },

    AcceptChangeRequestAttachment(Id) {
        return axios.post(`api/admin/Patients/${Id}/ChangeRequest/Accept`);
    },

    RejectChangeRequest(bodyObject) {
        return axios.post(`api/admin/Patients/ChangeRequest/Reject`, bodyObject);
    },



    //Change Request Attahcment
    AddChangeRequestAttahcment(bodyObject) {
        return axios.post(`api/admin/Patients/ChangeRequest/Attachments/Add`, bodyObject);
    },

    GetChangeRequestAttachment(Id) {
        return axios.get(`api/admin/Patients/ChangeRequest/Attachments/Get?Id=${Id}`);
    },

    DeleteChangeRequestAttachment(Id) {
        return axios.post(`api/admin/Patients/${Id}/ChangeRequest/Attachments/Delete`);
    },




    //Daily Used 
    GetTodayPatients(PatientId) {
        return axios.get(`api/admin/Patients/DailyUsed/GetTodayPatient?PatientId=${PatientId}`);
    },

    GetTodayPatientsManual(PatientId) {
        return axios.get(`api/admin/Patients/DailyUsed/GetTodayPatientsManual?PatientId=${PatientId}`);
    },

    NewAttendance(Id) {
        return axios.post(`api/admin/Patients/${Id}/DailyUsed/Add`);
    },

    NewAttendanceAll() {
        return axios.post(`api/admin/Patients/DailyUsed/AddAll`);
    },

    NewAttendanceManuel(bodyObject) {
        return axios.post(`api/admin/Patients/DailyUsed/AddManuel`, bodyObject);
    },

    NewAttendanceManuelF(bodyObject) {
        return axios.post(`api/admin/Patients/DailyUsed/AddManuelF`, bodyObject);
    },

    GetUsedDaily(pageNo, pageSize, CityId, MunicipalitiesId, KidneyCenterId, PatientId, ByDate, CompanyId, DeviceId, FilterId) {
        return axios.get(`api/admin/Patients/DailyUsed/Get?pageNo=${pageNo}&pagesize=${pageSize}&CityId=${CityId}
            &MunicipalitiesId=${MunicipalitiesId}&KidneyCenterId=${KidneyCenterId}&PatientId=${PatientId}
            &ByDate=${ByDate}&CompanyId=${CompanyId}&DeviceId=${DeviceId}&FilterId=${FilterId}`);
    },

    DeleteAttendance(Id) {
        return axios.post(`api/admin/Patients/${Id}/DailyUsed/Delete`);
    },

    GetUsedDailyReportInfo(pageNo, pageSize, CityId, MunicipalitiesId, KidneyCenterId, From,To, CompanyId, DeviceId, FilterId) {
        return axios.get(`api/admin/Patients/DailyUsed/GetReport?pageNo=${pageNo}&pagesize=${pageSize}&CityId=${CityId}
            &MunicipalitiesId=${MunicipalitiesId}&KidneyCenterId=${KidneyCenterId}
            &From=${From}&To=${To}&CompanyId=${CompanyId}&DeviceId=${DeviceId}&FilterId=${FilterId}`);
    },

    GetUsedDailyRequired(pageNo, pageSize, CityId, MunicipalitiesId, KidneyCenterId, CompanyId, DeviceId, FilterId) {
        return axios.get(`api/admin/Patients/DailyUsed/GetRequired?pageNo=${pageNo}&pagesize=${pageSize}&CityId=${CityId}
            &MunicipalitiesId=${MunicipalitiesId}&KidneyCenterId=${KidneyCenterId}
            &CompanyId=${CompanyId}&DeviceId=${DeviceId}&FilterId=${FilterId}`);
    },














    // ********************************************************************| Companies |***********************************************************

    GetCompanies(pageNo, pageSize) {
        return axios.get(`api/admin/Companies/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    AddCompanies(bodyObject) {
        return axios.post(`api/admin/Companies/Add`, bodyObject);
    },

    EditCompanies(bodyObject) {
        return axios.post(`api/admin/Companies/Edit`, bodyObject);
    },

    DeleteCompanies(Id) {
        return axios.post(`api/admin/Companies/${Id}/Delete`);
    },



    //Device
    GetDevices(pageNo, pageSize, CompanyId) {
        return axios.get(`api/admin/Companies/Devices/Get?pageno=${pageNo}&pagesize=${pageSize}&CompanyId=${CompanyId}`);
    },

    AddDevices(bodyObject) {
        return axios.post(`api/admin/Companies/Devices/Add`, bodyObject);
    },

    EditDevices(bodyObject) {
        return axios.post(`api/admin/Companies/Devices/Edit`, bodyObject);
    },

    DeleteDevices(Id) {
        return axios.post(`api/admin/Companies/${Id}/Devices/Delete`);
    },



    // Filters
    GetFilters(pageNo, pageSize, CompanyId, DeviceId) {
        return axios.get(`api/admin/Companies/Filters/Get?pageno=${pageNo}&pagesize=${pageSize}&CompanyId=${CompanyId}&DeviceId=${DeviceId}`);
    },

    AddFilters(bodyObject) {
        return axios.post(`api/admin/Companies/Filters/Add`, bodyObject);
    },

    EditFilters(bodyObject) {
        return axios.post(`api/admin/Companies/Filters/Edit`, bodyObject);
    },

    DeleteFilters(Id) {
        return axios.post(`api/admin/Companies/${Id}/Filters/Delete`);
    },



    // KidneyCenters
    GetKidneyCenters(pageNo, pageSize, CityId, MunicipalitiesId) {
        return axios.get(`api/admin/Companies/KidneyCenters/Get?pageno=${pageNo}&pagesize=${pageSize}&CityId=${CityId}&MunicipalitiesId=${MunicipalitiesId}`);
    },

    GetKidneyCentersById(Id) {
        return axios.get(`api/admin/Companies/KidneyCenters/GetById?Id=${Id}`);
    },

    GetKidneyCentersDevice(KidneyCentersId) {
        return axios.get(`api/admin/Companies/KidneyCenters/GetDevice?KidneyCentersId=${KidneyCentersId}`);
    },

    AddKidneyCenters(bodyObject) {
        return axios.post(`api/admin/Companies/KidneyCenters/Add`, bodyObject);
    },

    EditKidneyCenters(bodyObject) {
        return axios.post(`api/admin/Companies/KidneyCenters/Edit`, bodyObject);
    },

    DeleteKidneyCenters(Id) {
        return axios.post(`api/admin/Companies/${Id}/KidneyCenters/Delete`);
    },



    // KidneyCenters Device
    GetKidneyCentersDeviceInfo(pageNo, pageSize, CompanyId, DeviceId) {
        return axios.get(`api/admin/Companies/KidneyCenters/Devices/GetDeviceInfo?pageno=${pageNo}&pagesize=${pageSize}
            &CompanyId=${CompanyId}&DeviceId=${DeviceId}`);
    },

    AddKidneyCentersDevice(bodyObject) {
        return axios.post(`api/admin/Companies/KidneyCenters/Devices/Add`, bodyObject);
    },

    EditKidneyCentersDevice(bodyObject) {
        return axios.post(`api/admin/Companies/KidneyCenters/Devices/Edit`, bodyObject);
    },

    DeleteKidneyCentersDevice(Id) {
        return axios.post(`api/admin/Companies/${Id}/KidneyCenters/Devices/Delete`);
    },




    // Municipalities
    GetMunicipalities(pageNo, pageSize, CityId) {
        return axios.get(`api/admin/Companies/Municipalities/Get?pageno=${pageNo}&pagesize=${pageSize}&CityId=${CityId}`);
    },

    AddMunicipalities(bodyObject) {
        return axios.post(`api/admin/Companies/Municipalities/Add`, bodyObject);
    },

    EditMunicipalities(bodyObject) {
        return axios.post(`api/admin/Companies/Municipalities/Edit`, bodyObject);
    },

    DeleteMunicipalities(Id) {
        return axios.post(`api/admin/Companies/${Id}/Municipalities/Delete`);
    },


    // Cities
    GetCitie(pageNo, pageSize) {
        return axios.get(`api/admin/Companies/Cities/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    AddCities(bodyObject) {
        return axios.post(`api/admin/Companies/Cities/Add`, bodyObject);
    },

    EditCities(bodyObject) {
        return axios.post(`api/admin/Companies/Cities/Edit`, bodyObject);
    },

    DeleteCities(Id) {
        return axios.post(`api/admin/Companies/${Id}/Cities/Delete`);
    },


    // Nationalites
    GetNationalitesInfo(pageNo, pageSize) {
        return axios.get(`api/admin/Companies/Nationalites/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    AddNationalites(bodyObject) {
        return axios.post(`api/admin/Companies/Nationalites/Add`, bodyObject);
    },

    EditNationalites(bodyObject) {
        return axios.post(`api/admin/Companies/Nationalites/Edit`, bodyObject);
    },

    DeleteNationalites(Id) {
        return axios.post(`api/admin/Companies/${Id}/Nationalites/Delete`);
    },














    // ********************************************************************| Users |***********************************************************
    GetUsers(pageNo, pageSize, UserType, KidneyCenterId) {
        return axios.get(`api/admin/User/Get?pageNo=${pageNo}&pagesize=${pageSize}&userType=${UserType}&KidneyCenterId=${KidneyCenterId}`);
    },

    AddUser(bodyObject) {
        return axios.post("api/admin/User/Add", bodyObject);
    },

    EditUser(bodyObject) {
        return axios.post("api/admin/User/Edit", bodyObject);
    },

    ChangeStatusUser(Id) {
        return axios.post(`api/admin/User/${Id}/ChangeStatus`);
    },

    RestePassword(Id) {
        return axios.post(`api/admin/User/${Id}/RestePassword`);
    },

    DeleteUser(Id) {
        return axios.post(`api/admin/User/${Id}/Delete`);
    },

    UploadImage(bodyObject) {
        return axios.post("/Api/Admin/User/UploadImage", bodyObject);
    },

    EditUsersProfile(bodyObject) {
        return axios.post("/Api/Admin/User/EditUsersProfile", bodyObject);
    },

    ChangePassword(userPassword) {
        return axios.post(`/Api/Admin/User/ChangePassword`, userPassword);
    },











    // ********************************************************************| Dashboard Info |***********************************************************
    GetDashboardInfo() {
        return axios.get(`/Api/Admin/Reports/GetDashboardInfo`);
    },

















    


























    // ********************************************************************| Helper |***********************************************************
    GetCities() {
        return axios.get(`api/admin/Cities/GetAll`);
    },

    //GetMunicipalitiesName(id) {
    //    return axios.get(`/api/admin/Municipalities/GetAll?id=${id}`);
    //  },

    GetAllMunicipalitiesName() {
        return axios.get(`/api/admin/Municipalities/GetAll`);
    },

    GetMunicipalitiesName(id) {
        return axios.get(`/Api/Admin/Helper/GetMunicipalitiesName?id=${id}`);
    },


    GetMunicipalitiesByCiteisID(id) {
        return axios.get(`api/admin/Municipalities/GetById?CityId=${id}`);
    },

    GetByIdKidneyCenterId(id) {
        return axios.get(`api/admin/Patients/GetById?KidneyCenterId=${id}`);
    },

    GetByPatientInfo(NationalId, FileNumber) {
        return axios.get(`api/admin/Patients/GetByPatientInfo?NationalId=${NationalId}&FileNumber=${FileNumber}`);
    },


    UpDateMuntisbl(bodyObject) {
        return axios.post(`api/admin/Municipalities/Edit`, bodyObject);
    },

    KidneyCenters(pageNo, pageSize) {
        return axios.get(
            `api/admin/KidneyCenters/Get?pageNo=${pageNo}&pageSize=${pageSize}`
        );
    },

    deleteMunticbil(id) {
        return axios.post(`api/admin/Municipalities/${id}/Delete`);
    },

    //deleteKidneyCenters(id) {
    //    return axios.post(`api/admin/KidneyCenters/${id}/Delete`);
    //},

    //AddKidneyCenters(bodyObject) {
    //    return axios.post(`api/admin/KidneyCenters/Add`, bodyObject);
    //},

    UpdateKidneyCenters(bodyObject) {
        return axios.post(`api/admin/KidneyCenters/Edit`, bodyObject);
    },

    GetAllMunicipalities(cityid) {
        return axios.get(`api/admin/Municipalities/GetAll?cityid=${cityid}`);
    },

    GetLocationsName(id) {
        return axios.get(`/Api/Admin/Helper/GetLocationsName?id=${id}`);
    },

    ChangePasswords(object) {
        return axios.post(`/Api/Admin/User/ChangePassword`, object);
    },

    

    GetUsersHospital(pageNo, pageSize, KidneyCentersId) {
        return axios.get(
            `/Api/Admin/User/GetUsersHospital?pageNo=${pageNo}&pagesize=${pageSize}&KidneyCentersId=${KidneyCentersId}`
        );
    },

    GetUsersPerType(pageNo, pageSize, UserType) {
        return axios.get(
            `/Api/Admin/User/GetUsersPerType?pageNo=${pageNo}&pagesize=${pageSize}&userType=${UserType}`
        );
    },

    

    // **********************************    Applications ***********************

    AddApplications(bodyObject) {
        return axios.post(`/Api/Admin/Applications/Add`, bodyObject);
    },

    EditApplications(bodyObject) {
        return axios.post(`/Api/Admin/Applications/Edit`, bodyObject);
    },

    GetApplications(pageNo, pageSize, MunicipalitId, SelectedHospitalsId) {
        return axios.get(
            `/Api/Admin/Applications/Get?pageno=${pageNo}&pagesize=${pageSize}&MunicipalitId=${MunicipalitId}&selectedHospitalsId=${SelectedHospitalsId}`
        );
    },

    GetAttachments(id) {
        return axios.get(`api/admin/Patients/GetAttachments?PatientId=${id}`);
    },

    AddAttachment(bodyObject) {
        return axios.post(`api/admin/Patients/AddAttachments`, bodyObject);
    },

    DeleteAttachment(id) {
        return axios.post(`api/admin/Patients/${id}/RemoveAttachments`);
    },

    AddImage(bodyObject) {
        return axios.post(`api/admin/Patients/ChangePatintImage`, bodyObject);
    },

    GetPhones(id) {
        return axios.get(
            `api/admin/Patients/GetPatientPhoneNumbers?PatientId=${id}`
        );
    },

    AddPhones(bodyObject) {
        return axios.post(`api/admin/Patients/AddPatientPhoneNumbers`, bodyObject);
    },

    DeletePhones(id) {
        return axios.post(`api/admin/Patients/${id}/DeletePatientPhoneNumbers`);
    },

    deleteApp(id) {
        return axios.post(`/Api/Admin/Applications/${id}/delete`);
    },

    GetFilterById(id) {
        return axios.get(`api/admin/Filters/GetById?DeviceId=${id}`);
    },

    GetBranchesName(id) {
        return axios.get(`/Api/Admin/Applications/GetBranchesNames?cityId=${id}`);
    },
    //GetPatientSchedule(id) {
    //    return axios.get(`api/admin/Patients/GetPatientSchedule?PatientId=${id}`);
    //},
    GetBranchesAllName() {
        return axios.get(`/Api/Admin/Applications/GetAllBranchesNames`);
    },

    // **********************************    Hospitals ***********************
    //AddPatientSchedule(b) {
    //    return axios.post(`api/admin/Patients/AddPatientSchedule`, b);
    //},
    GetHospitalsAllName(MunicipalitId) {
        return axios.get(
            `/Api/Admin/Hospitals/GetAllHospitalsNames?MunicipalitId=${MunicipalitId}`
        );
    },

    //DeletePatientSchedule(id) {
    //    return axios.post(`api/admin/Patients/${id}/DeletePatientSchedule`);
    //},

    AddHospitals(bodyObject) {
        return axios.post(`/Api/Admin/Hospitals/Add`, bodyObject);
    },

    GetHospitals(pageNo, pageSize, MunicipalitId) {
        return axios.get(
            `/Api/Admin/Hospitals/Get?pageno=${pageNo}&pagesize=${pageSize}&MunicipalitId=${MunicipalitId}`
        );
    },

    EditHospitals(bodyObject) {
        return axios.post(`/Api/Admin/Hospitals/Edit`, bodyObject);
    },

    deleteHospitals(id) {
        return axios.post(`/Api/Admin/Hospitals/${id}/delete`);
    },

    

    GetRequiment() {
        return axios.get(`/Api/Admin/Municipalities/GetRequiment`);
    },


    getManticiplintKednyCenter(id) {
        return axios.get(`api/admin/Patients/GetByMunicipalitId?MunicipalitId=${id}`);
    },
    /////   Devices ///////

    //GetDevices(pageNo, pageSize, id) {
    //    return axios.get(
    //        `api/admin/Devices/Get?pageNo=${pageNo}&pageSize=${pageSize}&companyid=${id}`
    //    );
    //},

    GetAllDevices() {
        return axios.get(
            `api/admin/Devices/GetAll`
        );
    },

    //AddDevices(bodyObject) {
    //    return axios.post(`api/admin/Devices/Add`, bodyObject);
    //},

    UpdateDevices(bodyObject) {
        return axios.post(`api/admin/Devices/Edit`, bodyObject);
    },

    //deleteDevices(id) {
    //    return axios.post(`api/admin/Devices/${id}/Delete`);
    //},

    GetAllCompanies() {
        return axios.get(`api/admin/Companies/GetAll`);
    },
    GetDviceById(id) {
        return axios.get(
            `api/admin/Devices/GetById?pageNo=1&pageSize=1000&CompanyId=${id}`
        );
    },
    /////   Devices ///////

    AddDeviceToKindycenter(bodyObject) {
        return axios.post(`api/admin/KidneyCentersDevices/Add`, bodyObject);
    },
    EditDeviceToKindycenter(bodyObject) {
        return axios.post(`api/admin/KidneyCentersDevices/Edit`, bodyObject);
    },
    /////// Company   //////////////

    deleteDeviceKidny(id) {
        return axios.post(`api/admin/KidneyCentersDevices/${id}/Delete`);
    },
    GetCompany(pageNo, pageSize) {
        return axios.get(
            `api/admin/Companies/Get?pageNo=${pageNo}&pageSize=${pageSize}`
        );
    },

    AddCompany(bodyObject) {
        return axios.post(`api/admin/Companies/Add`, bodyObject);
    },

    GetFelter(pageNo, pageSize, id) {
        return axios.get(
            `api/admin/Filters/Get?pageNo=${pageNo}&pageSize=${pageSize}&filterid=${id}`
        );
    },
    UpdateCompany(bodyObject) {
        return axios.post(`api/admin/Companies/Edit`, bodyObject);
    },

    deleteCompany(id) {
        return axios.post(`api/admin/Companies/${id}/Delete`);
    },

    deleteFelter(id) {
        return axios.post(`api/admin/Filters/${id}/Delete`);
    },

    DeletePatientAttendance(id) {
        return axios.post(`api/admin/Patients/${id}/DeletePatientAttendance`);
    },

    UpdateFilters(bodyObject) {
        return axios.post(`api/admin/Filters/Edit`, bodyObject);
    },

    //AddFilters(bodyObject) {
    //    return axios.post(`api/admin/Filters/Add`, bodyObject);
    //},



    PrintPatients(pageNo, pageSize, MunicipalitId, HospitalId, companiesId) {
        return axios.get(`api/admin/Reports/Print/Patients?pageNo=${pageNo}&pageSize=${pageSize}&MunicipalityId=${MunicipalitId}&KidneyCenterId=${HospitalId}&CompanyId=${companiesId}`, { responseType: 'arraybuffer' });
    },

    deletePatient(id) {
        return axios.post(`api/admin/Patients/${id}/Delete`);
    },

    ShowDevices(id) {
        return axios.get(
            `api/admin/KidneyCentersDevices/Get?KidneyCentersId=${id}`
        );
    },

    GetNationalites() {
        return axios.get(`api/admin/Nationalites/GetAll`);
    },

    /////// Company //////////

    // **********************************    Municipalities ***********************

    //   GetAllMunicipalities() {
    //     return axios.get(`/Api/Admin/Municipalities/GetAll`);
    //   },

    //AddMunicipalities(bodyObject) {
    //    return axios.post(`/Api/Admin/Municipalities/AddWithoutCity`, bodyObject);
    //},

    //GetMunicipalities(pageNo, pageSize) {
    //    return axios.get(
    //        `/Api/Admin/Municipalities/Get?pageno=${pageNo}&pagesize=${pageSize}`
    //    );
    //},

    //EditMunicipalities(bodyObject) {
    //    return axios.post(`/Api/Admin/Municipalities/EditWithoutCity`, bodyObject);
    //},

    //DeleteMunicipalities(id) {
    //    return axios.post(`/Api/Admin/Municipalities/${id}/Delete`);
    //},

    // **********************************    GetDevicesName ***********************

    GetDevicesNames() {
        return axios.get(`/Api/Admin/Devices/GetDevicesName`);
    },

    GetDevicesbyCompany(id) {
        return axios.get(`/Api/Admin/Devices/GetById?CompanyId=${id}`);
    },

    GetFilterNames(id) {
        return axios.get(`/Api/Admin/Filters/GetById?DeviceId=${id}`);
    },

    GetAllFilterNames() {
        return axios.get(`/Api/Admin/Devices/GetAllNames`);
    },

    // **********************************    Get Change Center ***********************

    

    GetChangeAttachments(id) {
        return axios.get(
            `/Api/Admin/Patients/GetChangeAttachments?PatientsChangeCenterId=${id}`
        );
    },



    GetKidneyCentersNames() {
        return axios.get(`/Api/Admin/KidneyCenters/GetAll`);
    },

    

    //****************************************************************************************
    

    GetPatientNames() {
        return axios.get(`/Api/Admin/Patients/GetAll`);
    },

    GetCompanyNames() {
        return axios.get(`/Api/Admin/Companies/GetAll`);
    },

    GetUsed() {
        return axios.get(`/Api/Admin/Reports/GetUsed`);
    },

    GetUsedRequierd() {
        return axios.get(`/Api/Admin/Reports/GetUsedRequierd`);
    },

    GetByDate(day1, day2) {
        return axios.get(
            `/Api/Admin/Reports/GetUsedByDate?From=${day1}&To=${day2}`
        );
    },

    AddPatientAttendance(bodyObject) {
        return axios.post(`api/admin/Patients/AddPatientAttendance`, bodyObject);
    },

    GetKednyCenterByCitie(id) {
        return axios.get(`api/admin/KidneyCenters/GetById?MunicipalityId=${id}`);
    },
    GetKednyCenterByMunicipality(id) {
        return axios.get(`api/admin/KidneyCenters/GetById?MunicipalityId=${id}`);
    },










    UpdatePationtScagualList(schema) {
        return axios.post(`api/admin/Patients/EditPatientSchedule`, schema);
    },


    //AddHospitals(bodyObject) {
    //    return axios.post(`/Api/Admin/Devices/Add`, bodyObject);
    //},

    //GetHospitals(pageNo, pageSize, SelectedCityId) {
    //    return axios.get(`/Api/Admin/Devices/GetBranches?pageno=${pageNo}&pagesize=${pageSize}&selectedCityId=${SelectedCityId}`);
    //},

    //EditHospitals(bodyObject) {
    //    return axios.post(`/Api/Admin/Devices/EditBranches`, bodyObject);
    //},

    //deleteHospitals(id) {
    //    return axios.post(`/Api/Admin/Devices/${id}/deleteBranches`);
    //},
};
