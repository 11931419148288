import Swal from "sweetalert2";
import moment from "moment";
import HelperMixin from '../../../Shared/HelperMixin.vue'

export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        this.GetCities();
        this.GetCompanies();
        this.GetInfo();







        //this.GetPatientNames();
        //this.GetCompanyNames();
        //this.GetKidneyCenters();

        //this.GetMunicipalities();
    },
    components: {
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('DD/MM/YYYY || h:mm a');
        },
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,



            Info: [],
            Cities: [],
            Municipalities: [],
            KidnyCenter: [],
            TodayPatient: [],

            Companies: [],
            Devices: [],
            Felters: [],



            CityId: '',
            MunicipalitiesId: '',
            KidneyCenterId: '',

            CompanyId:'',
            DeviceId:'',
            FilterId:'',


            Patient: [],
            PatientId: '',
            ByDate: '',


            ManualAddDiloag: false,
            ManualForgenAddDiloag: false,



            ruleForm: {
                PatientId: '',
                CompanyId: '',
                DeviceId: '',
                FilterId: '',
                UsedCount: '',
            },
            rules: {
                PatientId: this.$helper.Required(),
                CompanyId: this.$helper.Required(),
                DeviceId: this.$helper.Required(),
                FilterId: this.$helper.Required(),
                UsedCount: this.$helper.Required(),
            },




















            CityId2: "",
            citis: [],

            FileNumber: "",
            
            KidneyCenters: [],
            ViewInfoDialog: false,

            
        };
    },
    methods: {

        GetCities() {
            this.$blockUI.Start();
            this.$http
                .GetCities()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Cities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetMunicipalities() {
            this.Municipalities = [];
            this.MunicipalitiesId = '';
            this.ruleForm.MunicipalitiesId = '';
            this.KidnyCenter = [];
            this.KidneyCenterId = '';
            this.ruleForm.KidneyCenterId = '';
            this.GetInfo();
            this.$blockUI.Start();
            this.$http.GetMunicipalitiesByCiteisID(this.CityId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetKednyCenter() {
            this.KidnyCenter = [];
            this.KidneyCenterId = '';
            this.ruleForm.KidneyCenterId = '';
            this.GetInfo();
            this.$blockUI.Start();
            this.$http
                .GetKednyCenterByCitie(this.MunicipalitiesId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.KidnyCenter = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetCompanies() {
            this.$blockUI.Start();
            this.$http
                .GetAllCompanies()
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Companies = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetDviceById() {
            this.Devices = [];
            this.DeviceId = '';
            this.Felters = [];
            this.FilterId = '';
            this.$blockUI.Start();
            this.$http
                .GetDviceById(this.CompanyId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Devices = response.data.info;
                    this.GetInfo();
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetFilterById() {
            this.Felters = [];
            this.FilterId = '';
            this.$blockUI.Start();
            this.$http
                .GetFilterById(this.DeviceId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Felters = response.data.info;
                    this.GetInfo();
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetDviceById_Add() {
            this.Devices = [];
            this.ruleForm.DeviceId = '';
            this.Felters = [];
            this.ruleForm.FilterId = '';
            this.$blockUI.Start();
            this.$http
                .GetDviceById(this.ruleForm.CompanyId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Devices = response.data.info;
                    this.GetInfo();
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetFilterById_Add() {
            this.Felters = [];
            this.ruleForm.FilterId = '';
            this.$blockUI.Start();
            this.$http
                .GetFilterById(this.ruleForm.DeviceId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Felters = response.data.info;
                    this.GetInfo();
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        FilterByName() {
            this.Patient = [];
            let code = "";
            if (document.getElementById('selectInputName') != null) {

                if (document.getElementById('selectInputName').value == null || document.getElementById('selectInputName').value == '')
                    return;

                code = document.getElementById('selectInputName').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetPatientById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Patient = response.data.info;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    console.error(err);
                });
        },

        FilterByNid() {
            this.Patient = [];
            let code = "";
            if (document.getElementById('selectInputNid') != null) {

                if (document.getElementById('selectInputNid').value == null || document.getElementById('selectInputNid').value == '')
                    return;

                code = document.getElementById('selectInputNid').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetPatientById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Patient = response.data.info;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    console.error(err);
                });
        },

        FilterByPass() {
            this.Patient = [];
            let code = "";
            if (document.getElementById('selectInputPass') != null) {

                if (document.getElementById('selectInputPass').value == null || document.getElementById('selectInputPass').value == '')
                    return;

                code = document.getElementById('selectInputPass').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetPatientById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Patient = response.data.info;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    console.error(err);
                });
        },

        FilterByName_Add() {
            this.Patient = [];
            let code = "";
            if (document.getElementById('selectInputName_Add') != null) {

                if (document.getElementById('selectInputName_Add').value == null || document.getElementById('selectInputName_Add').value == '')
                    return;

                code = document.getElementById('selectInputName_Add').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetPatientByIdActive(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Patient = response.data.info;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    console.error(err);
                });
        },


        FilterByName_AddF() {
            this.Patient = [];
            let code = "";
            if (document.getElementById('selectInputName_AddF') != null) {

                if (document.getElementById('selectInputName_AddF').value == null || document.getElementById('selectInputName_AddF').value == '')
                    return;

                code = document.getElementById('selectInputName_AddF').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetPatientByIdAllActive(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Patient = response.data.info;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    console.error(err);
                });
        },


        OpenAutoAddDiloag() {
            this.state = 1;
            this.GetTodayPatient();
            this.Refresh();
        },

        GetTodayPatient() {
            this.$blockUI.Start();
            this.$http
                .GetTodayPatients(this.PatientId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.TodayPatient = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        GetManulTodayPatient() {
            this.$blockUI.Start();
            this.$http
                .GetTodayPatientsManual(this.PatientId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Patient = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        OpenManualForgenAddDiloag() {
            this.ruleForm.PatientId = '';
            this.ruleForm.CompanyId = '';
            this.ruleForm.DeviceId = '';
            this.ruleForm.FilterId = '';
            this.ruleForm.UsedCount = '';
            this.ManualForgenAddDiloag = true;
        },
        
        OpenManualAddDiloag() {
            this.ruleForm.PatientId = '';
            this.ruleForm.CompanyId = '';
            this.ruleForm.DeviceId = '';
            this.ruleForm.FilterId = '';
            this.ruleForm.UsedCount = '';
            this.GetManulTodayPatient();
            this.ManualAddDiloag = true;
        },

        Attend(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية تسجيل حضور المريض  ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.NewAttendance(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetTodayPatient();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },

        AttendAll() {
            Swal.fire({
                title: "هـل انت متأكد من عملية تسجيل حضور جميع المرضي  ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.NewAttendanceAll()
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetTodayPatient();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية التسجيل', err.response.data);
                        });
                    return;
                }
            });
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.ruleForm.UsedCount = Number(this.ruleForm.UsedCount);
                    this.$blockUI.Start();
                    this.$http.NewAttendanceManuel(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.Refresh();
                            this.ManualAddDiloag = false;
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        submitFormF(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.ruleForm.UsedCount = Number(this.ruleForm.UsedCount);
                    this.$blockUI.Start();
                    this.$http.NewAttendanceManuelF(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.Refresh();
                            this.ManualForgenAddDiloag = false;
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            if (this.ByDate)
                this.ByDate = this.ChangeDate(this.ByDate);
            this.$blockUI.Start();
            this.$http
                .GetUsedDaily(this.pageNo, this.pageSize, this.CityId, this.MunicipalitiesId, this.KidneyCenterId,
                    this.PatientId, this.ByDate, this.CompanyId, this.DeviceId, this.FilterId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟ ",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteAttendance(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },




        Back() {
            this.state = 0;
            this.TodayPatient = [];
            this.Refresh();
        },

        Refresh() {
            this.CityId = '';
            this.Municipalities = '';
            this.MunicipalitiesId = '';
            this.KidnyCenter = '';
            this.KidneyCenterId = '';
            this.PatientId = '';
            this.Patient = [];
            this.Devices = [];
            this.DeviceId = '';
            this.CompanyId = '';
            this.Felters = [];
            this.FilterId = '';
            this.ByDate = '';

            this.GetInfo();
            
        },

        ChangeDate(date) {
                if (date === null) {
                    return "فارغ";
                }
                return moment(date).format("YYYY-MM-DD");
        },
    },
};
